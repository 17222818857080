module.exports = {
  '/// adresy url stranek': '------',
  'O klinice': 'ÜBER DIE KLINIK',
  'Náš tým': 'UNSER TEAM',
  'Zákroky': 'EINGRIFFE',
  'Před a po': 'VOHER UND DANACH',
  'RECENZE': 'REZENSIONEN',
  'Blog': 'BLOG',
  'Kontakt': 'KONTAKT',
  'Nadace': 'STIFTUNG',
  'about - meta title': 'Über uns',
  'O nás': 'Über uns',
  '/about': '/uber-uns',
  '/procedures': '/eingriffe',
  '/blog': '/blog',
  'blog - meta title': 'Blog',
  'Volejte': 'Rufen Sie an',
  'Proměny před a po našich klientů': 'Verwandlungen unserer Klienten - vorher und danach',
  'další fotky před a po': 'WEITERE FOTOS VORHER UND DANACH',
  '/// meta informace statickych stranek': '------',
  'další články': 'Weitere Artikel',
  'blog': 'blog',
  'FORMÉ clinic': 'Formé clinic',
  '2013 - 2019 © Formé clinic. Všechna práva vyhrazena.': '2013 - 2020 © Formé Clinic. Alle Rechte vorbehalten.',
  'Klinika pro lidi': 'Wir ändern das Aussehen, wir ändern das Leben.',
  'Používáme jen ověřené chirurgické postupy, za které můžeme ručit. Proto ať už Vás k nám přivedou na plastiku vrozené dispozice, věk, nebo úraz, jsme připraveni Vám pomoci.': 'Wir verändern Ihr Aussehen und dadurch Ihr Leben Sie möchten sich schöner und selbstbewusster fühlen? Dann sind Sie bei uns genau richtig. Es erwartet Sie unser Team aus fachlich versierten, renommierten Plastischen Chirurgen, Anästhesisten und Krankenschwestern, die Ihnen die besten Leistungen erbringen und Sie auf Ihrem Weg zum gewünschten Aussehen begleiten.',
  'Jsme výjimeční osobním přístupem. Nechodí k nám nechodí jen celebrity, jsme klinika pro běžné lidi, s rodinnou atmosférou. Nejsme továrna na nová těla, ale specializovaná klinika s pěti lůžky, kde je dost času na nadstandard.': 'Wir zeichnen uns durch individuelle Leistungen und medizinische Pflege auf hohem Niveau aus. Unser Ziel ist eine natürliche Brustform entsprechend Ihrem individuellen Aussehen und Ihrem Wunsch. Maximale Sicherheit, schonende Behandlung und Komfort haben für uns höchste Priorität.',
  'Přijďte navštívit naši kliniku a zeptejte se našich odborníků na cokoliv. Vždy si na vás rádi udělají čas.': 'Wir haben das Leben unserer Klienten zum Positiven verändert und ihre Träume erfüllt. Die höchsten Bewertungen in unabhängigen Rezensionen sind der Beweis dafür.',
  'kteří se obrátili na naši kliniku a užívají si výsledky naší práce. A že je něco jinak, neuvidíte jen Vy, ale také Vaše okolí.': 'Sich für einen chirurgischen Eingriff zu entscheiden, fällt nicht leicht. Vereinbaren Sie einen unabhängigen Beratungstermin, um sich ein genaues Bild von den Behandlungsmethoden zu machen. Unsere Ärzte werden Ihnen immer die beste Lösung empfehlen.',
  'Náš tým se skládá ze skvělých profesionálů ve svém oboru. Postará se o Vaše pohodlí, zdraví i bezpečí. Pokud se chcete svěřit do rukou lidem, kteří mají rádi svou práci a záleží jim na tom, co dělají, jste na správném místě.': 'Um Ihr Wohl kümmern sich die renommiertesten Plastischen Chirurgen aus dem In- und Ausland mit jahrelanger Berufspraxis. Ihre Zufriedenheit, Gesundheit und Sicherheit steht für unsere Ärzte immer an erster Stelle. ',
  'Proč si vybrat nás?': 'Spitzen-Niveau gehört bei uns zum Standard',
  'Konzultace je zdarma a nezávazná': 'Wir haben Tausenden von Klienten geholfen',
  'Apartmány v budově kliniky': 'Wir garantieren Ihnen höchsten Qualitäts',
  'Na naší klinice máme k dispozici moderní apartmány pro klienty z daleka i pro rodinné příslušníky.': 'Dabei setzen wir auf modernste Geräte und Therapien, die schonendste Anästhesie und zertifizierte Materialien mit höchster Qualitätsgarantie.',
  'Přes 70 tisíc spokojených klientů': 'Das Beratungsgespräch ist unabhängig',
  'Více o naší klinice': 'MEHR ZU UNSERER KLINIK',
  'Naši lékaři': 'Unser Team besteht aus den besten Fachärzten',
  'Co máme za sebou': 'Wir schätzen ihr entgegengebrachten Vertrauen',
  'operací celkově': 'nicht-invasive Eingriffe',
  'zvětšení prsou': 'Operationen',
  'spokojených klientů': 'zufriedene Klienten',
  'kombinovaných operací': 'kombinierte Operationen',
  'Online konzultace': 'Online <br/> Konsultationen',
  'contact form - homepage text': 'Sich für einen Eingriff zu entscheiden, ist nicht einfach. Haben Sie Fragen oder möchten Sie eine zweite Meinung einholen? <b>Beraten Sie sich mit unseren Chirurgen</b>. Vereinbaren Sie einen unverbindlichen <b>Konsultationstermin</b>. Um so schnell wie möglich einen geeigneten Termin für die Beratung zu finden und eventuelle Fragen zu besprechen, <b>schreiben Sie uns bitte Ihre Telefonnummer und wir rufen Sie gerne an</b>.',
  'Jméno a příjmení': 'Vorname und Name',
  'Email': 'E-Mail-Adresse',
  'Dotaz': 'Anfrage',
  'Přidat fotku': 'Hinzufügen eines Fotos',
  'Jsme výjimeční naším osobním přístupem. Nechodí k nám nechodí jen celebrity, jsme klinika pro běžné lidi, s rodinnou atmosférou. Máme all inclusive balíčky. Nejsme továrna na nová těla, ale specializovaná klinika s pěti lůžky, kde je dost času na nadstandard.': 'Wir sind durch unser persönliches Engagement einzigartig. Wie sind eine Klinik mit familiärer Atmosphäre für jedermann. Wir sind keine Fabrik für neue Körper, sondern eine spezialisierte Klinik mit Appartements mit über dem Standard liegendem Komfort.',
  'Odeslat': 'ABSENDEN',
  'Ceny najdete v detailu jednotlivého zákroku.': 'Die Preise finden Sie in der Detailbeschreibung des jeweiligen Eingriffs.',
  'Co říkají naši klienti': 'Was unsere Klienten sagen',
  'více recenzí': 'MEHR REZENSIONEN',
  'Otevřeno': 'Geöffnet:',
  'Sledujte nás': 'Folgen Sie uns',
  'zavolejte mi': 'RUFEN SIE MICH AN',
  'Zadejte telefonní číslo v mezinárodním formátu a my se vám ozveme.': 'Geben Sie Ihre Telefonnummer in internationalem Format ein und wir setzen uns mit Ihnen in Verbindung.',
  'Zavoláme vám': 'Wir rufen Sie an',
  'Přijďte se podívat. Dáte si kávu, prohlédnete si kliniku a zeptáte se lékaře na všechno, co Vás zajímá. Jsme stále online. Poradíme, i když to k nám máte daleko. S našimi lékaři si můžete povídat i z pohodlí vašeho domova.': 'Folgen Sie unserer Einladung und überzeugen Sie sich selbst. Trinken Sie eine Tasse Kaffee, sehen Sie sich die Klinik an und stellen Sie den Ärzten Ihre Fragen zu allem, was Sie interessiert. Wir sind ständig online. Wir beraten Sie, selbst wenn Sie es weit zu uns haben. Mit unserem Personal sind Sie auch von zu Hause aus in Kontakt.',
  'Vše pod jednou střechou': 'Alles unter einem Dach',
  'Vybavení': 'Ausstattung',
  'Veškerá základní vyšetření potřebná k zákrokům zařídíme na naší klinice. Bez starostí provedeme EKG i krevní testy': 'Sämtliche grundlegenden Untersuchungen, die für die Eingriffe erforderlich sind, besorgen wir in unserer Klinik. Ohne jedwede Sorgen führen wir das EKG und die Blutuntersuchungen durch.',
  'Strava': 'Verpflegung',
  'Během rekonvalescence na klinice podáváme kvalitní a pestrou BIO stravu, jejíž složení je přesně uzpůsobeno pro co nejlepší regeneraci po zákroku. Nezapomínáme ani na alergiky, vegetariány a vegany.': 'Während der Rekonvaleszenz in der Klinik reichen wir eine hochwertige und vielfältige BIO- Nahrung, deren Zusammensetzung im Sinne der besten Regenerierung nach dem Eingriff exakt angepasst ist. Darüber hinaus vergessen wir auch nicht die Allergiker, Vegetarierer und Veganer.',
  'Zábava': 'Unterhaltung',
  'Pro pohodlný odpočinek a regeneraci. Během hospitalizace máte k dispozici tablet s wifi připojením nebo dvd přehrávač s filmy mnoha žánrů. U nás se nudit nebudete.': 'Für die angenehme Erholung und Regenerierung Während Ihres Klinikaufenthaltes steht Ihnen ein Tablet mit WLAN-Anschluss oder ein DVD-Player mit Filmen vieler Genres zur Verfügung. Bei uns werden Sie sich gewiss nicht langweilen.',
  'Personál': 'Personal',
  'Můžeme se chlubit tím nejlepším personálem, za kterým stojí velký tým plastických chirurgů a anesteziologů se zkušenostmi z celého světa. Náš ochotný personál včetně klient koordinátorů ovládá dva světové jazyky.': 'Wir können uns mit dem besten Personal rühmen, hinter welchem das große Team der plastischen Chirurgen und Anästhesisten mit Erfahrungen aus aller Welt steht. Unser hilfsbereites Personal, einschließlich der Klientenkoordinatoren, beherrscht zwei Weltsprachen.',
  'Taxi': 'Taxi',
  'Na vaše pohodlí myslíme i po operaci. Dopravu domů nemusíte řešit. Po zákrocích v celkové i lokální anestezii vás v rámci Prahy odvezeme zdarma.': 'An Ihren Komfort denken wir auch nach der Operation. Über die Beförderung nach Hause müssen Sie sich keine Sorgen machen. Nach den Eingriffen unter Voll- oder lokaler Anästhesie bringen wir Sie im Rahmen Prags unentgeltlich an den gewünschten Ort.',
  'Materiál': 'Material',
  'Pracujeme jen s těmi nejlepšími materiály renomovaných značek v nejvyšší dostupné kvalitě. Používáme pouze prvotřídní implantáty, šití i nejlépe hodnocené léky pro šetrnou anestezii. Pohodlné pooperační kompresní prádlo je samozřejmostí.': 'Wir arbeiten nur mit den besten Materialien renommierter Marken in der höchsten, verfügbaren Qualität. Wir verwenden lediglich erstklassige Implantate, die besten Fäden für das Nähen sowie die bestbewerteten Präparate für eine schonende Anästhesie. Eine bequeme, postoperative Kompressionswäsche ist eine Selbstverständlichkeit.',
  'All inclusive': 'All-inclusive',
  'Každý zákrok je all inclusive. V jeho ceně tak máte anestezii, hospitalizaci v moderních pokojích, kompresní prádlo, pooperační kontroly i balíček léků pro správnou péči po zákroku. Po propuštění jsou vám naši lékaři kdykoliv k dispozici na telefonu i na emailu.': 'Jeder Eingriff ist allumfassend. In seinem Preis sind die Anästhesie, die Hospitalisierung in modernen Zimmern, die Kompressionswäsche, die postoperativen Kontrollen sowie das Paket der Medikamente für die richtige Pflege nach dem Eingriff umfasst. Nach der Entlassung stehen Ihnen unsere Ärzte telefonisch oder per E-Mail jederzeit zur Verfügung.',
  'Pojištění': 'Versicherung',
  'Jsme pojištěni u Kooperativa, můžete být bez starostí. Naše klinika má sjednané nejspolehlivější pojištění.': 'Wir sind bei der Versicherungsgesellschaft Kooperativa versichert, sodass Sie sich keine Sorgen machen müssen. Unsere Klinik hat die zuverlässigste Versicherung vereinbart.',
  'Jak to u nás vypadá?': 'Wie es bei uns aussieht?',
  'Zobrazit všechny fotografie': 'ALLE FOTOS ZEIGEN',
  'GDPR': 'DSGVO',
  'Zadejte telefonní číslo v mezinárodním formátu a ozvu se vám.': 'Geben Sie Ihre Telefonnummer in internationalem Format ein und wir setzen uns mit Ihnen in Verbindung.',
  'Všechna práva vyhrazena.': 'Alle Rechte vorbehalten.',
  'Interiér Formé clinic': 'Innenräume der Klinik',
  'Ten nejlepší odpočinek i po zákroku. Na naší klinice máme k dispozici moderní apartmány pro klienty z daleka i pro rodinné příslušníky. Můžete tak být nablízku našim lékařům i zdravotnickému personálu i po propuštění z kliniky.': 'Die beste Erholung auch nach dem Eingriff. In unserer Klinik stehen den Klienten von außerhalb sowie ihren Familienangehörigen moderne Appartements zur Verfügung. So haben Sie es auch nach der Entlassung aus der Klinik nicht weit zu unseren Ärzten sowie zum medizinischen Personal.',
  'Co byste měli vědět o zákroku': 'Was Sie über den Eingriff wissen sollten',
  'Uváděná cena zákroků je orientační. Může se změnit ještě po prohlídce lékařem.': 'Der angeführte Preis der Eingriffe dient lediglich zur Orientierung. Er kann sich nach der persönlichen Konsultation ändern.',
  'Zákrok se platí předem.': 'Der Eingriff wird im Voraus bezahlt.',
  'Vlastní předoperační vyšetření je velké plus, urychlý tak ranní proces na klinice.': 'Eine eigene Untersuchung vor der Operation ist ein großes Plus, jedoch nicht Bedingung. Sie kann auch in unserer Klinik am Tage der Operation gewährleistet werden.',
  'Máte dotaz nebo se chcete domluvit na konzultaci?': 'Haben Sie eine Frage oder wollen Sie ein Konsultation vereinbaren?',
  'contact form - text': 'Sich für einen Eingriff zu entscheiden, ist nicht einfach. Haben Sie Fragen oder möchten Sie eine zweite Meinung einholen? <b>Beraten Sie sich mit unseren Chirurgen</b>. Vereinbaren Sie einen unverbindlichen <b>Konsultationstermin</b>. Um so schnell wie möglich einen geeigneten Termin für die Beratung zu finden und eventuelle Fragen zu besprechen, <b>schreiben Sie uns bitte Ihre Telefonnummer und wir rufen Sie gerne an</b>.',
  'Vlastní předoperační vyšetření je velké plus, urychlí tak ranní proces na klinice.': 'Eine eigene Untersuchung vor der Operation ist ein großes Plus, jedoch nicht Bedingung. Sie kann auch in unserer Klinik am Tage der Operation gewährleistet werden.',
  'team - intro': 'Unser Team besteht aus hervorragenden, in ihrem Fachbereich erfahrenen Mitarbeitern. Es kümmert sich um Ihren Komfort, Ihre Gesundheit und Ihre Sicherheit. Sofern Sie sich unserer Betreuung anvertrauen, garantieren wir Ihnen, dass Sie im gesamten Prozess nicht allein sein werden. Wir helfen Ihnen bei der Entscheidung, bei der Planung und beim Eingriff selbst. Wir stellen fest, was für Sie das Beste ist und gewährleisten mittels individuell gestalteter Betreuung, dass die von uns erbrachten Leistungen bereits ab dem ersten Handschlag bis zum Abschied am Ende der Behandlung von höchster Qualität sind. <br />  <br /> Wir freuen uns auf Sie. ',
  'Všichni zaměstnanci': 'ALLE MITARBEITER',
  'procedures - meta desc': 'Hier finden Sie eine Liste aller von uns ausgeführten Eingriffe. Die Konsultationen sind unverbindlich. Besuchen Sie unsere Klinik und fragen Sie unsere Experten nach allem, was Sie interessiert. Sie nehmen sich für Sie stets gern Zeit. Den ungefähren Preis finden sie jeweils beim konkreten Eingriff.',
  'Zde najdete seznam všech zákroků, které provádíme. Konzultujeme bezplatně a nezávazně. Přijďte navštívit naši kliniku a zeptejte se našich odborníků na cokoliv. Vždy si na vás rádi udělají čas. Orientační cenu najdete vždy u konkrétníko zákroku.': 'Hier finden Sie eine Liste aller von uns ausgeführten Eingriffe. Die Konsultationen sind unverbindlich. Besuchen Sie unsere Klinik und fragen Sie unsere Experten nach allem, was Sie interessiert. Sie nehmen sich für Sie stets gern Zeit. Den ungefähren Preis finden sie jeweils beim konkreten Eingriff.',
  'Kombinace zákroků': 'Kombinationen der Eingriffe',
  'Díky jednomu operačnímu výkonu k nám budete mít jen jednu cestu a absolvujete jen jednu operaci a následné hojení. Spojení zákroků je rychlejší a efektnější.': 'Dank nur einer Operation absolvieren Sie zu uns lediglich einen Weg und einen anschließenden Heilungsprozess. Die Verbindung der Eingriffe ist schneller und effizienter.',
  'Tuto unikátní službu ocení třeba novopečené maminky, které se necítí po porodu ve vlastním těle dobře, touží po zeštíhlení či zpevnění problémových partií.': 'Diesen einzigartigen Dienst wissen unter anderem frisch gebackene Mütter zu schätzen, die sich in ihrem eigenen Körper nach der Entbindung nicht wohlfühlen, sich nach Schlankheit oder nach einer Festigung der Problempartien sehnen.',
  'Časté kombinace': 'Häufige Kombinationen',
  'Operaci poprsí a liposukce': 'Operation der Brüste und Liposuktion',
  'Liposukce více partií najednou': 'Liposuktion mehrerer Partien zugleich',
  'Modelace prsou a operace břicha': 'Modellierung der Brüste und Operation des Bauches',
  'Spojte několik zákroků do jednoho operačního výkonu a ušetřete nejen finance, ale i váš čas.': 'Verbinden Sie mehrere Eingriffe zu einer Operationsleistung und sparen Sie nicht nur Geld, sondern auch Zeit.',
  'Lékař s vámi probere všechna vaše trápení a navrhne možné kombinace, které jsou pro vás nejvhodnější a nejlépe vyhovují vašemu zdravotnímu stavu.': 'Der Arzt erörtert mit Ihnen all Ihre Leiden und schlägt mögliche Kombinationen vor, die für Sie am geeignetsten sind und Ihrem Gesundheitszustand am besten entsprechen.',
  'Jak se k nám dostanete?': 'Wie Sie uns gelangen?',
  'Tady bude text o tom, jak se klienti do Formé Clinic dostanou. Možná info o MHD a hlavně že můžou parkovat v garážích zdarma.': 'Sie finden uns 10 Minuten mit der Straßenbahn vom Hauptbahnhof in Prag entfernt. Von der Straßenbahnhaltestelle Hauptbahnhof (Hlavní nádraží) fahren in unsere Richtung die Straßenbahnlinien 5, 9, 5 und 26 bis zur Haltestelle Olšanské náměstí, von wo es nur 5 Minuten Fußweg in Richtung zum Park Parukářka sind. Ihr Auto können Sie in unseren Garagen parken.',
  '/changes': '/voher_und_danach',
  '/contact': '/kontakt',
  'Najdete nás 10 minut tramvají od Hlavního nádraží v Praze. Z tramvajové zastávky Hlavní nádraží k nám jedou tramvaje 5, 9, 15 a 26 na zastávku Olšanské náměstí a odtud je to 5 minut směr park Parukářka. Autem můžete parkovat u nás v garážích.': 'Sie finden uns 10 Minuten mit der Straßenbahn vom Hauptbahnhof in Prag entfernt. Von der Straßenbahnhaltestelle Hauptbahnhof (Hlavní nádraží) fahren in unsere Richtung die Straßenbahnlinien 5, 9, 5 und 26 bis zur Haltestelle Olšanské náměstí, von wo es nur 5 Minuten Fußweg in Richtung zum Park Parukářka sind. Ihr Auto können Sie in unseren Garagen parken.',
  '/foundation': '/stiftung',
  'Časté dotazy': 'Häufig gestellte Fragen',
  'Kontakty': 'Kontakt',
  'Primář Formé Clinic': 'Chefarzt der Formé Clinic',
  'Plastický chirurg': 'Plastischer Chirurg',
  'Plastický chirurg Formé Clinic': 'Plastischer Chirurg',
  'Proměny našich klientů': 'Verwandlungen unserer Klienten',
  'Podívejte se na proměny našich klientů. Najdete u nich videa, rozhovory s lékaři, fotografie před a po ošetření a dojmy a recenze klientů. Jak jsou spokojeni s výsledkem, co říkají na naše doktory a jak se jim líbili prostory Formé Clinic?': 'Sehen Sie sich die Verwandlungen unserer Klienten an. Sie finden bei ihnen Videos, Gespräche mit den Ärzten, Fotografien vor und nach der Behandlung sowie Eindrücke und Rezensionen der Klienten. Wie sie mit dem Ergebnis zufrieden sind, was sie zu ihren Ärzten sagen und wie ihnen die Räumlichkeiten der Formé Clinic gefielen.',
  'changes - meta desc': 'Sehen Sie sich die Verwandlungen unserer Klienten an. Sie finden bei ihnen Videos, Gespräche mit den Ärzten, Fotografien vor und nach der Behandlung sowie Eindrücke und Rezensionen der Klienten. Wie sie mit dem Ergebnis zufrieden sind, was sie zu ihren Ärzten sagen und wie ihnen die Räumlichkeiten der Formé Clinic gefielen.',
  'Všechny zákroky': 'Alle Eingriffe',
  'Více o zákroku': 'Mehr zum eingriff',
  'Mohlo by vás zajímat': 'Das könnte Sie interessieren.',
  'team - meta title': 'Unser team',
  'faq - meta title': 'Häufig gestellte Fragen',
  'Recenze': 'Rezensionen',
  '/faq': '/haufig_gestellte_fragen',
  '/team': '/unser_team',
  '/references': '/empfehlung',
  'Zavolám vám': 'Ich rufen Sie an',
  'Děkujeme!': 'Vielen Dank!',
  'Obratem se vám ozveme a domluvíme se na nezávaznou konzultaci.': 'Wir melden uns umgehend bei Ihnen und wir vereinbaren einen unverbindlichen Termin.',
  '404 - meta title': 'Die Seite nicht gefunden.',
  'procedures - meta title': 'Operationen und Eingriffen',
  'Stránka nebyla nalezena': 'Die Seite nicht gefunden',
  'Přejít na FORMÉ clinic': 'Auf Formé clinic gehen',
  'changes - meta title': 'Vorher und Nachher - Verwandlung',
  'team - meta desc': 'Unser Team besteht aus ausgezeichneten Fachleuten in ihrem Bereich. Unsere Chirurgen operieren nicht nur in der Tschechischen Republik, sondern auch in Europa oder in den USA. ',
  'references - meta title': 'Bewertungen unserer Kunden',
  'references - meta desc': 'Was sagen die Kunden über uns. Bewertungen direkt von der renommierten unabhängigen Webseite Estheticon.',
  'contact - meta title': 'Melden Sie sich bei uns',
  'další příběhy': 'andere Erfahrungen',
  'Plastická chirurgie je pro běžné lidi, nejen pro celebrity': 'Plastische Chirurgie ist für jeden',
  'Jaké zákroky provádí': 'Welche Eingriffe führt er',
  'Cena zákroku': 'Der Preis',
  'Zákrok provádí': 'Der Eingriff wird durchgeführt',
  'dalších {arg1} fotografií': 'mehrere {arg1} Fotos',
  'dalších <br /> {arg1} fotografií': 'mehrere <br /> {arg1} Fotos',
  'více o balíčcích': 'mehr über medizinische Päckchen',
  'Celý tým': 'Das ganze Team',
  'Email je povinný, prosím doplňte': 'Die Email-Adresse ist erforderlich, bitte ergänzen Sie',
  'Recenze na': 'Bewertungen auf',
  'Jméno je povinné, prosím doplňte': 'Der Name ist erforderlich, bitte ergänzen Sie',
  'Dotaz je povinný, prosím doplňte': 'Die Frage ist erforderlich, bitte ergänzen Sie',
  'Email není validní, prosím doplňte': 'Die Email-Adresse ist nicht gültig, bitte ergänzen Sie',
  'další <br /> {arg1} fotografie': 'mehrere <br /> {arg1} Fotos',
  'smazat': 'löschen',
  'Došlo k chybě při odesílání formuláře, zkuste to prosím později.': 'Es ist ein Fehler aufgetreten, bitte verbuchen Sie es später.',
  'V pořádku': 'In Ordnung',
  'Více informací': 'Mehrere Informationen',
  'další proměny': 'weitere Verwandlungen',
  'Odesílám...': 'Es wird gesendet',
  '/apartments': '/apartments',
  'Koronavirus': 'Koronavirus',
  'contact - meta desc': 'Rufen Sie uns an oder schreiben Sie uns und wir melden bei Ihnen zurück. Wir sind täglich für Sie da.',
  'Vybíráme z vašich příběhů a pomáháme uskutečnit váš sen': 'Wir wählen von Ihren Geshichten aus und wir helfen Ihnen Ihren Traum verwirklichen.',
  'Děkujeme,<br />ozveme se vám obratem.': 'Wir bedanken uns <br />und melden uns umgehend bei Ihnen. ',
  'Plastická a estetická chirurgie v Praze · FORMÉ clinic': 'Plastische und ästhetische Chirurgie in Prag · FORMÉ clinic',
  'Apartmány': 'Apartments',
  'blog - meta desc': 'Verfolgen Sie Neuigkeiten und interessante Dinge aus der Welt der plastischen Chirurgie.',
  'about - meta desc': 'Renommierte Klinik für plastische Chirurgie in Prag, außergewöhnlich für unseren persönlichen Ansatz. Wir sind eine Klinik für alle mit familiärer Atmosphäre. Die Formé Clinic ist eine spezialisierte Klinik für plastische Chirurgie mit Apartments für Sie und Ihre Lieben.',
  'foundation - meta title': 'Formé-Stiftung',
  'foundation - meta desc': 'Die Idee des Stiftungsfonds wurde im Oktober 2017 mit dem Ziel ins Leben gerufen, umfassende chirurgische und beratende Unterstützung zu bieten und die Aktivitäten der Klinik den normalen Menschen näher zu bringen. Plastische Chirurgie ist nicht nur für die Reichen und Prominenten, sondern für alle, die ein Problem lösen müssen.',
  'Pomáháme běžným lidem': 'Wir helfen allen',
  'Myšlenka nadačního fondu vznikla v říjnu roku 2017 s cílem poskytnout všestrannou chirurgickou a poradenskou pomoc a přiblížit činnost kliniky běžným lidem. Plastická chirurgie není jen pro bohaté a pro celebrity, ale pro všechny, kteří potřebují vyřešit nějaký problém. Finanční prostředky z výtěžků nadačních akcí, příspěvky partnerů i drobných dárců jsou věnovány především na osvětovou činnost, na nákup implantátů a na pomoc klientům, kteří operaci potřebují, ale nemůžou si ji dovolit.': 'Die Idee des Stiftungsfonds wurde im Oktober 2017 mit dem Ziel ins Leben gerufen, umfassende chirurgische und beratende Unterstützung zu bieten und die Aktivitäten der Klinik den normalen Menschen näher zu bringen. Plastische Chirurgie ist nicht nur für die Reichen und Prominenten, sondern für alle, die ein Problem lösen müssen. Die Mittel aus dem Erlös der Stiftung Veranstaltungen, Beiträge von Partnern und kleinen Spendern gewidmet sind in erster Linie für Bildungsaktivitäten, den Kauf von Implantaten und zu helfen Kunden, die eine Operation benötigen, aber es sich nicht leisten können.',
  'Pomáháme řešit problémy těm, kteří si to nemohou dovolit': 'Wir helfen bei der Lösung von Problemen für jemandem, die es sich nicht leisten können.',
  'people - meta title': 'Menschen',
  'Co má za sebou': 'Was steckt dahinter',
  'Kariéra': 'Karriere',
  'Career - meta title': 'Karriere bei uns',
  'career - info': 'Die Formé-Klinik ist eine plastische und ästhetische Klinik mit einer 8-jährigen Geschichte. Unsere Mitarbeiter haben Erfahrungen in in- und ausländischen Kliniken gesammelt. Eines verbindet uns: Wir setzen unser Herz in unsere Arbeit ein. Wir sorgen dafür, dass sich nicht nur unsere Kunden, sondern auch unsere Mitarbeiter bei uns wohl fühlen. <br/> <br/> Unsere Kunden kehren sehr oft zu "ihrem" Arzt zurück und empfehlen unsere Dienstleistungen wärmstens. <Br /> <br/> Wir suchen Kollegen, die das Leben der Menschen, denen wir helfen, verändern und die Dinge anders machen wollen. Besser.',
  'Seznam aktuálních volných pozic:': 'Liste der aktuellen Stellenangebote:',
  'Zašli nám svůj strukturovaný životopis': 'Schicken Sie uns Ihren strukturierten Lebenslauf',
  'faq - meta desc': 'faq - meta desc',
  '/career': '/Karriere',
  'Další volné pozice:': 'Sonstige Stellenangebote:',
  'Staňte se součástí Formé rodiny. Hledáme lidi, kteří nám pomohou měnit osudy našich klientů.': 'Werden Sie Teil des Formé Familie. Wir suchen Kollegen, die uns helfen, das Leben unserer Kunden zu verändern.',
  'Career - meta desc': 'Werden Sie Teil des Formé Familie. Wir suchen Kollegen, die uns helfen, das Leben unserer Kunden zu verändern.',
  'EU FOOTER - cookies': 'Diese Website verwendet Cookies, damit wir sie für Sie ständig verbessern können. Wenn Sie nicht möchten, dass Cookies auf dieser Website geladen werden, können Sie sie löschen und in den Browsereinstellungen deaktivieren.',
  'Kontakt na apartmány': 'Kontakt für Apartment',
  'Pro rezervaci apartmánu <br />nás kontaktujte na:': 'Um eine Apartment zu buchen <br /> kontaktieren Sie uns unter:',
  'Plastická chirurgie': 'Plastische Chirurgie',
  'Ortopedie': 'Orthopädie',
  'nové': 'neu',
  'nově': 'neu',
  'Díky špičkovému vybavení moderních operačních sálů a profesionálnímu specialozovanému personálu vám Formé Clinic poskytne tu nejlepší péči v oblasti ortopedie. Zabýváme léčbou poruch pohybového aparátu dle nejnovějších medicínských poznatků.': 'Vertrauen Sie sich in die Hände unserer fachlich versierten Orthopäden. Wir bieten Therapien für den Stütz- und Bewegungsapparat nach den modernsten medizinischen Erkenntnissen. Unsere modernen mit Spitzentechnik ausgestatteten Operationssäle garantieren die beste medizinische Pflege.',
  'Ortopedické zákroky': 'Orthopädische Eingriffe',
  'Nově otevřeno': 'Neu geöffnet',
  'Estetická plastika': 'Ästhetische Chirurgie',
  'Nové': 'Neu',
  'Provádíme kompletní škálu estetických plastických operací - zvětšení a modelaci prsou, liposukci, plastiku nosu a další. Ať už vás k nám přivedou vrozené dispozice, věk, nebo úraz, jsme připraveni vám pomoci.': 'Wir führen eine komplette Palette ästhetischer plastischer Chirurgie durch - Brustvergrößerung und - straffung, Fettabsaugung, Nasenplastik und mehr. Egal, ob Sie durch angeborene Dispositionen, Alter oder Verletzung zu uns gebracht werden, wir sind bereit, Ihnen zu helfen.',
  'zákroky estetické plastiky': 'ästhetische Chirurgie',
  'ortopedické zákroky': 'orthopädische Eingriffe',
  'Klient koordinátor pro Ortopedii': 'Client-Koordinator für Orthopädie',
  'Telefon': 'Telefonnummer',
  'Aktuální informace': 'Aktuelle Informationen',
  'Máme pro vás otevřeno!': 'Wir haben geöffnet!',
  'Vaše bezpečnost je naše priorita.': 'Ihre Sicherheit ist unsere Priorität.',
  'Dodržujeme pravidlo 3R.': 'Wir folgen der Corona-Schutzmaßnahmen.',
  'Covid-19 info': 'Covid-19 info',
  'Při provedení všech zákroků a ošetření dbáme na maximální šetrnost a klademe důraz na bezpečnost pro vaše zdraví.': 'Bei der Vornahme aller Eingriffe und Behandlungen gehen wir mit höchster Sorgfalt vor und achten auf Ihren Gesundheitsschutz.',
  'Používáme nejmodernější přístroje a postupy s garancí vysoké kvality odborné péče profesionálů uznávaných u nás i v zahraničí.': 'Wir setzen auf modernste Technik und Behandlungsverfahren. Wir garantieren das hohe Niveau der Behandlung seitens unserer im In- und Ausland anerkannter Fachmitarbeiter. ',
  'Cena je orientační a může se lišit na základě vašich konkrétních potřeb a rozsahu zákroku. Upřesněna bude po osobní konzultaci s lékařem.': 'Der Preis dient der Orientierung und kann entsprechend Ihren konkreten Anforderungen und dem Behandlungsumfang variieren. Den genauen Preis erfahren Sie nach Ihrem Arztgespräch.',
  'Používáme nejmodernější přístroje a postupy s garancí vysoké kvality odborné péče profesionálů uznávaných u nás i v zahraničí. ': 'Wir setzen auf modernste Technik und Behandlungsverfahren. Wir garantieren das hohe Niveau der Behandlung seitens unserer im In- und Ausland anerkannter Fachmitarbeiter. ',
  'Velice nás těší Váš zájem o osobní konzultaci ve Formé clinic. Nejpozději do dvou pracovních dnů se Vám ozveme a domluvíme společně termín konzultace, který Vám bude nejvíce vyhovovat. Těšíme se na Vás.': 'Wir freuen uns sehr über Ihr Interesse an einer persönlichen Beratung in der Formé Klinik. Wir werden uns spätestens innerhalb von zwei Arbeitstagen mit Ihnen in Verbindung setzen und einen für Sie am besten geeigneten Beratungstermin vereinbaren. Wir freuen uns auf Euch.',
  'Váš tým Formé clinic': 'Ihr Formé Klinik Team',
  '/dev-404-page': '',
  '/404': '',
  '/404.html': '',
  '/': '/',
  'Formé clinic je zdravotnické zařízení. Návštěva kliniky a veškeré zákroky jsou <b>v souladu s aktuálními vládními opatřeními COVID-19 platnými v České republice.</b>': 'Wir sind eine medizinische Klinik. Der Besuch der Klinik und sämtliche Eingriffe stehen  <b>im Einklang mit den aktuellen seitens der Regierung der Tschechischen Republik ergriffenen COVID-19-Maßnahmen.</b>',
  'Pro rychlejší komunikaci nám prosím napište telefonní číslo.': 'Geben Sie Ihre Telefonnummer in internationalem Format (+49...).',
  '<b>Máme pro vás stále otevřeno.</b> Formé clinic je zdravotnické zařízení. Návštěva kliniky a veškeré zákroky jsou <b>v souladu s aktuálními vládními opatřeními COVID-19 platnými v České republice.</b> V případě cesty z jiného okresu vám vystavíme <b>potvrzení o návštěvě lékaře.</b>': '<b>Wir haben geöffnet!</b> Formé clinic ist medizinische Einrichtung. Der Besuch der Klinik und sämtliche Eingriffe stehen <b>im Einklang mit den aktuellen, seitens der Regierung der Tschechischen Republik ergriffenen COVID-19-Maßnahmen.</b> Damit Sie uns einfach erreichen können, senden wir Ihnen eine Artztbestätigung über den Besuch.',
  '           Formé clinic je zdravotnické zařízení. Návštěva kliniky a veškeré zákroky jsou <b>v souladu s aktuálními vládními opatřeními COVID-19 platnými v České republice.</b>': '---empty---',
  'Akceptujeme tyto benefitní karty': '',
  'Nově nabízíme možnost rozložit si cenu zákroku až do 24 měsíčních plateb bez navýšení.': '',
  'Dopřejte si změnu hned a plaťte pak!': '',
  'Plastická a estetická medicína': 'Plastische und Ästhetische Chirurgie',
  'Provádíme kompletní škálu estetických plastických operací. Ať už vás k nám přivedou vrozené dispozice, touha po krásnějším vzhledu nebo úraz, jsme připraveni vám pomoci.': 'Wir bieten eine breite Skala an plastisch-chirurgischen Eingriffen. Wir helfen Ihnen im Falle angeborener Dispositionen, eines Unfalls und beim Wunsch nach schönerem Aussehen gerne weiter.',
  'Dopřejte si změnu hned a plaťte pak. Nově nabízíme možnost rozložit si cenu zákroku až do 24 měsíčních plateb bez navýšení.': '',
  'FORMÉ clinic a kontakt': '',
  'plastických operací': '',
  'Odesláním formuláře souhlasíte se': 'Odesláním formuláře souhlasíte se',
  'zásadami zpracování a ochrany osobních údajů.': 'zásadami zpracování a ochrany osobních údajů.',
  'Telefon je povinný, prosím doplňte': 'Die Telefonnummer ist erforderlich, bitte ergänzen Sie',
  'Všechny otázky': 'alle Fragen',
  'Trvalá epilace': 'Dauerhafte Haarentfernung',
  'Zbavíme vás nežádoucího ochlupení jednou pro vždy. Jako jediní v České republice disponujeme revolučním lékařským diodovým laserem OPT LED Excelsior 3.0 s unikátní 3D hlavicí, který nám umožňuje odstranit i ty nejjemnější a nejsvětlejší chloupky s téměř 100% účinností.': 'Verabschieden Sie sich ein für alle Mal von unerwünschter Behaarung. Als landesweit einzige Einrichtung verfügen wir über den revolutionären medizinischen Diodenlaser OPT LED Excelsior 3.0 mit einzigartigem 3D-Kopf, der mit beinahe 100% Erfolg  auch die feinsten und hellsten Haare entfernt.',
  'Chci se objednat': 'TERMIN BUCHEN',
  'Dermatologie': '',
  'Fotografie před a po': '',
  'Storno podmínky': 'Stornobedingungen',
  'Zdravotní pohotovost': 'Medizinischer Notfall',}