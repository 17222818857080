module.exports = {
  '/about': '/about-us',
  '/procedures': '/procedures',
  '/blog': '/blog',
  'blog - meta title': 'Blog',
  '404 - meta title': 'Page not found',
  'O nás': 'About Us',
  'Náš tým': 'Our Team',
  'Blog': 'Blog',
  'blog - meta desc': 'Follow news and curiosities from the world of plastic surgery.',
  'O klinice': 'About the clinic',
  'Zákroky': 'Procedures',
  'Před a po': 'Before and after',
  'RECENZE': 'REVIEWS',
  'Kontakt': 'Contact',
  'Nadace': 'Foundation',
  'procedures - meta title': 'Procedures and operations',
  'Stránka nebyla nalezena': 'Page not found',
  'Přejít na FORMÉ clinic': 'Go to Formé Clinic',
  'procedures - meta desc': 'Here you can find a list of all the procedures we perform. We consult without obligation. Come visit our clinic and ask our experts for anything. They will be always happy to spend time with you. You can always find an approximate price at the specific procedure.',
  'about - meta title': 'About us',
  'team - meta title': 'Our team',
  'changes - meta title': 'Before and after - transformations',
  'references - meta title': 'Reviews from our clients',
  'foundation - meta title': 'Formé Foundation',
  'contact - meta title': 'Contact us',
  'Volejte': 'Call',
  'další články': 'more articles',
  'Mohlo by vás zajímat': 'You might be interested',
  'blog': 'blog',
  'další příběhy': 'more stories',
  'Proměny našich klientů': 'Transformations of our clients',
  'Proměny před a po našich klientů': 'Transformations of our clients before and after',
  'další fotky před a po': 'more pictures before and after',
  'Pomáháme řešit problémy těm, kteří si to nemohou dovolit': 'We help to solve problems for those who cannot afford it',
  'Vybíráme z vašich příběhů a pomáháme uskutečnit váš sen': 'We choose from your stories and help to realize your dream',
  'Kombinace zákroků': 'Procedure combinations',
  'Spojte několik zákroků do jednoho operačního výkonu a ušetřete nejen finance, ale i váš čas.': 'Combine several procedures into one operation and save not only finances but also your time.',
  'people - meta title': 'People',
  'Kontakty': 'Contacts',
  'Zavoláme vám': 'We will call you',
  'Otevřeno': 'Open',
  'Zadejte telefonní číslo v mezinárodním formátu a my se vám ozveme.': 'Enter your phone number in an international format and we will contact you.',
  'FORMÉ clinic': 'Formé clinic',
  'zavolejte mi': 'Call me',
  'Časté dotazy': 'Frequently Asked Questions',
  'Kariéra': 'Career',
  'Sledujte nás': 'Follow us',
  '2013 - 2019 © Formé clinic. Všechna práva vyhrazena.': '2013 - 2019 © Formé clinic. All rights reserved.',
  'Cena zákroku': 'Price of procedure',
  'Co byste měli vědět o zákroku': 'What you should know about a procedure',
  'Zákrok provádí': 'Procedure performs',
  'Časté kombinace': 'Often combinations',
  'Liposukce více partií najednou': 'Liposuction of multiple areas at once',
  'Modelace prsou a operace břicha': 'Breast modeling and abdominal surgery',
  'více o balíčcích': 'more about packages',
  'Career - meta title': 'Career with us',
  'Seznam aktuálních volných pozic:': 'List of current vacancies:',
  'Zašli nám svůj strukturovaný životopis': 'Send us your CV',
  'faq - meta title': 'FAQ - Questions and answers',
  'Všichni zaměstnanci': 'All employees',
  'Přidat fotku': 'Add picture',
  'Jméno a příjmení': 'Name and surname',
  'Email': 'E-mail',
  'Dotaz': 'Query',
  'Proč si vybrat nás?': 'Above standard is our standard',
  'Konzultace je zdarma a nezávazná': 'Thousands of satisfied clients',
  'Vše pod jednou střechou': 'Everything under one roof',
  'Vybavení': 'Equipment',
  'Strava': 'Food',
  'Zábava': 'Fun',
  'Personál': 'Staff',
  'Taxi': 'Taxi',
  'Materiál': 'Material',
  'All inclusive': 'All-inclusive',
  'Pojištění': 'Insurance',
  'Jak to u nás vypadá?': 'How does it look like here?',
  'Apartmány v budově kliniky': 'Quality guarantee',
  'Více o naší klinice': 'More about our clinic',
  'Naši lékaři': 'A team of top doctors',
  'Celý tým': 'The whole team',
  'zvětšení prsou': 'breast enlargement',
  'spokojených klientů': 'satisfied clients',
  'Ceny najdete v detailu jednotlivého zákroku.': 'Prices can be found in the detail of the individual procedure.',
  'Co říkají naši klienti': 'What our clients say',
  'více recenzí': 'more reviews',
  'Recenze': 'Reviews',
  'Děkujeme!': 'Thank you!',
  'Email je povinný, prosím doplňte': 'Email is required, please fill in',
  'Jak se k nám dostanete?': 'How to get here?',
  'Obratem se vám ozveme a domluvíme se na nezávaznou konzultaci.': 'We will contact you immediately and arrange a non-binding consultation.',
  'Více o zákroku': 'More about the procedure',
  '/career': '/career',
  'Jméno je povinné, prosím doplňte': 'Name is required, please fill in',
  'Dotaz je povinný, prosím doplňte': 'Question is required, please fill in',
  'Email není validní, prosím doplňte': 'Email is not valid, please fill in',
  'smazat': 'delete',
  'Došlo k chybě při odesílání formuláře, zkuste to prosím později.': 'There was an error submitting the form, please try again later.',
  'EU FOOTER - cookies': 'This website uses cookies to improve your experience. If you do not want cookies to be loaded on this site, you can clear and disable cookies in your browser settings.',
  'V pořádku': 'OK',
  'Více informací': 'More information',
  'Zavolám vám': 'I will call you',
  'Recenze na': 'Reviews on ',
  'kombinovaných operací': 'combination procedures',
  'Co máme za sebou': 'Your trust is important to us',
  'Přes 70 tisíc spokojených klientů': 'Non-binding and consultation',
  'Každý zákrok je all inclusive. V jeho ceně tak máte anestezii, hospitalizaci v moderních pokojích, kompresní prádlo, pooperační kontroly i balíček léků pro správnou péči po zákroku. Po propuštění jsou vám naši lékaři kdykoliv k dispozici na telefonu i na emailu.': 'Every procedure is all inclusive. The price includes anesthesia, hospitalization in modern rooms, compression underwear, postoperative controls and a package of medicines for proper care after the procedure. After release, our doctors are available to you at any time by phone or email.',
  'Online konzultace': 'Online <br/> consultation',
  'Operaci poprsí a liposukce': 'Breast surgery and liposuction',
  'Zákrok se platí předem.': 'The procedure is paid in advance.',
  'dalších {arg1} fotografií': 'more {arg1} pictures',
  'dalších <br /> {arg1} fotografií': 'more <br /> {arg1} pictures',
  'Máte dotaz nebo se chcete domluvit na konzultaci?': 'Do you have a question or do you want to arrange a consultation?',
  'Pomáháme běžným lidem': 'We help people',
  'další <br /> {arg1} fotografie': 'more <br /> {arg1} pictures',
  'Děkujeme,<br />ozveme se vám obratem.': 'Thank you, <br /> we will contact you immediately.',
  'operací celkově': 'performed operations',
  'kteří se obrátili na naši kliniku a užívají si výsledky naší práce. A že je něco jinak, neuvidíte jen Vy, ale také Vaše okolí.': 'Make a choice of procedure is not just that. Come for a non-binding consultation and get a perfect idea of an available and specific solution. The doctor will always recommend the best. Ordering.',
  'Na naší klinice máme k dispozici moderní apartmány pro klienty z daleka i pro rodinné příslušníky.': 'We use the most modern devices and procedures, the most gentle anesthesia and certified materials with a guarantee of high quality.',
  'team - intro': 'Our team consists of excellent professionals in their field. They will take care of your comfort, health and safety. If you trust us, we can ensure that during the whole process you will not be alone. We will help you with decision-making, planning and actual surgery. We will find out what is the best for you and an individual approach guarantees that the services we provide will be of the highest quality from the first handshake to the final farewell.  <br />  <br /> We look forward to you.',
  'Veškerá základní vyšetření potřebná k zákrokům zařídíme na naší klinice. Bez starostí provedeme EKG i krevní testy': 'All activities associated with the surgery are done in one place. We do ECG and blood tests without any worries.',
  'Na vaše pohodlí myslíme i po operaci. Dopravu domů nemusíte řešit. Po zákrocích v celkové i lokální anestezii vás v rámci Prahy odvezeme zdarma.': 'After interventions in general and local anesthesia we arrange pick-client taxis within Prague free.',
  'about - meta desc': 'Renowned clinic of plastic surgery in Prague. We are exceptional by our personal approach. Not only celebrities come to us, we are a clinic for ordinary people with a family atmosphere. We have all inclusive packages. We are not a factory for new bodies, but a specialized clinic with five beds, where there is enough time for premium services.',
  'team - meta desc': 'Our team consists of excellent professionals in their field. They have performed thousands of surgeries. Our surgeons do not only operate in the Czech Republic, but also elsewhere in Europe or the USA.',
  'changes - meta desc': 'Look at the make over of our clients. You can find videos, interviews with doctors, before and after photos, and client´s impressions and reviews. How satisfied are they with the result, what they say about our doctors, and how did they like Formé Clinic?',
  'references - meta desc': 'What our clients say about us. Reviews from renovated and independent portal Estheticon.',
  'foundation - meta desc': 'The idea of the Endowment Fund was created in October 2017 with the aim of providing all-round surgical and counseling assistance and bringing the clinic\'s activities closer to ordinary people. Plastic surgery is not only for rich people and for celebrities, but for all who need to solve a problem.',
  'contact - meta desc': 'Email us, call us, and we will call you back. We are here for you every day.',
  'Podívejte se na proměny našich klientů. Najdete u nich videa, rozhovory s lékaři, fotografie před a po ošetření a dojmy a recenze klientů. Jak jsou spokojeni s výsledkem, co říkají na naše doktory a jak se jim líbili prostory Formé Clinic?': 'Look at the make over of our clients. You can find videos, interviews with doctors, before and after photos, and client´s impressions and reviews. How satisfied are they with the result, what they say about our doctors, and how did they like Formé Clinic?',
  'Myšlenka nadačního fondu vznikla v říjnu roku 2017 s cílem poskytnout všestrannou chirurgickou a poradenskou pomoc a přiblížit činnost kliniky běžným lidem. Plastická chirurgie není jen pro bohaté a pro celebrity, ale pro všechny, kteří potřebují vyřešit nějaký problém. Finanční prostředky z výtěžků nadačních akcí, příspěvky partnerů i drobných dárců jsou věnovány především na osvětovou činnost, na nákup implantátů a na pomoc klientům, kteří operaci potřebují, ale nemůžou si ji dovolit.': 'The idea of the Endowment Fund was created in October 2017 with the aim of providing all-round surgical and counseling assistance and bringing the clinic\'s activities closer to ordinary people. Plastic surgery is not only for rich people and for celebrities, but for all who need to solve a problem. Funds from the proceeds of endowment projects, contributions from partners and small donors are devoted primarily to awareness raising activities, to the purchase of implants and to help clients who need surgery but cannot afford it.',
  'Plastická chirurgie je pro běžné lidi, nejen pro celebrity': 'Plastic surgery is for ordinary people, not just for celebrities.',
  'Zde najdete seznam všech zákroků, které provádíme. Konzultujeme bezplatně a nezávazně. Přijďte navštívit naši kliniku a zeptejte se našich odborníků na cokoliv. Vždy si na vás rádi udělají čas. Orientační cenu najdete vždy u konkrétníko zákroku.': 'Here you can find a list of all the procedures we perform. We consult without obligation. Come visit our clinic and ask our experts for anything. They will be always happy to spend time with you. You can always find an approximate price at the specific procedure.',
  'Díky jednomu operačnímu výkonu k nám budete mít jen jednu cestu a absolvujete jen jednu operaci a následné hojení. Spojení zákroků je rychlejší a efektnější.': 'Thanks to one surgical procedure you come to us just once and you undergo only one operation and recovery. Combination of procedures is faster and more effective.',
  'Lékař s vámi probere všechna vaše trápení a navrhne možné kombinace, které jsou pro vás nejvhodnější a nejlépe vyhovují vašemu zdravotnímu stavu.': 'The doctor will discuss with you all your troubles and will suggest possible combinations that are most suitable for you and your health.',
  'Tuto unikátní službu ocení třeba novopečené maminky, které se necítí po porodu ve vlastním těle dobře, touží po zeštíhlení či zpevnění problémových partií.': 'This unique service will be appreciated by new mums who don´t feel comfortable in their own body after giving birth, longing for slimming or strengthening of problem areas.',
  'Co má za sebou': 'What he/she have done',
  'Jaké zákroky provádí': 'Procedures he performs ',
  'Uváděná cena zákroků je orientační. Může se změnit ještě po prohlídce lékařem.': 'The price is approximate and will be specified after a personal consultation with a doctor.',
  'Vlastní předoperační vyšetření je velké plus, urychlý tak ranní proces na klinice.': 'Your own preoperative tests are a big plus, the morning process at the clinic is then quicker.',
  'Ten nejlepší odpočinek i po zákroku. Na naší klinice máme k dispozici moderní apartmány pro klienty z daleka i pro rodinné příslušníky. Můžete tak být nablízku našim lékařům i zdravotnickému personálu i po propuštění z kliniky.': 'The best rest even after the procedure. At our clinic we have modern apartments for clients from far away and for their family members. So you can be close to our doctors and medical staff even after being released from the clinic.',
  'Career - meta desc': 'Become part of the Formé Family. We are looking for people to help us change lives of our clients.',
  'Staňte se součástí Formé rodiny. Hledáme lidi, kteří nám pomohou měnit osudy našich klientů.': 'Become part of the Formé Family. We are looking for people to help us change lives of our clients.',
  'contact form - homepage text': 'It is not easy to decide to undergo a surgery. Do you have any questions or do you need a second opinion? <b>Consult that with our surgeons</b>. Arrange a non-binding <b>consultation</b>. In order that we find a suitable date for the consultation as soon as possible and to discuss any questions you may have, <b>please write us a telephone number and we will be happy to call you</b>.',
  'Jsme výjimeční naším osobním přístupem. Nechodí k nám nechodí jen celebrity, jsme klinika pro běžné lidi, s rodinnou atmosférou. Máme all inclusive balíčky. Nejsme továrna na nová těla, ale specializovaná klinika s pěti lůžky, kde je dost času na nadstandard.': 'We are exceptional by our personal approach. Not only celebrities come to us, we are a clinic for ordinary people with a family atmosphere. We have all inclusive packages. We are not a factory for new bodies, but a specialized clinic with five beds, where there is enough time for premium services.',
  'Přijďte se podívat. Dáte si kávu, prohlédnete si kliniku a zeptáte se lékaře na všechno, co Vás zajímá. Jsme stále online. Poradíme, i když to k nám máte daleko. S našimi lékaři si můžete povídat i z pohodlí vašeho domova.': 'Come and see. Have a coffee, see the clinic and ask the doctor about everything that interests you. We are still online. We will help you, even if you are far from us. You can also talk to our doctors from the comfort of your own home.',
  'Během rekonvalescence na klinice podáváme kvalitní a pestrou BIO stravu, jejíž složení je přesně uzpůsobeno pro co nejlepší regeneraci po zákroku. Nezapomínáme ani na alergiky, vegetariány a vegany.': 'During your convalescence at the clinic we serve quality and varied BIO food, whose composition is precisely adapted for the best recovery after the procedure. We also think about allergy sufferers, vegetarians and vegans.',
  'Pro pohodlný odpočinek a regeneraci. Během hospitalizace máte k dispozici tablet s wifi připojením nebo dvd přehrávač s filmy mnoha žánrů. U nás se nudit nebudete.': 'For a comfortable rest and regeneration. During hospitalization you have a tablet with wifi or a DVD player available with movies of many genres. You won\'t be bored with us.',
  'Můžeme se chlubit tím nejlepším personálem, za kterým stojí velký tým plastických chirurgů a anesteziologů se zkušenostmi z celého světa. Náš ochotný personál včetně klient koordinátorů ovládá dva světové jazyky.': 'We can boast byt the best staff, backed by a large team of plastic surgeons and anesthesiologists with experience from all over the world. Our helpful staff, including client coordinators, speak two world languages.',
  'Pracujeme jen s těmi nejlepšími materiály renomovaných značek v nejvyšší dostupné kvalitě. Používáme pouze prvotřídní implantáty, šití i nejlépe hodnocené léky pro šetrnou anestezii. Pohodlné pooperační kompresní prádlo je samozřejmostí.': 'We work only with the best materials of renowned brands in the highest quality. We use only top-class implants, sewing, and top-rated medications for gentle anesthesia. Convenient postoperative compression underwear is a matter of course.',
  'Jsme pojištěni u Kooperativa, můžete být bez starostí. Naše klinika má sjednané nejspolehlivější pojištění.': 'We are insured by Kooperativa, you don´t have to be worried. Our clinic has the most reliable insurance.',
  'Používáme jen ověřené chirurgické postupy, za které můžeme ručit. Proto ať už Vás k nám přivedou na plastiku vrozené dispozice, věk, nebo úraz, jsme připraveni Vám pomoci.': 'Do you want to be more beautiful and confident? Then you are at right place. A team of leading and recognized plastic surgeons, anesthesiologists and nurses is waiting for you, who will take care of you and guide you on your path to the dream transformation.',
  'Jsme výjimeční osobním přístupem. Nechodí k nám nechodí jen celebrity, jsme klinika pro běžné lidi, s rodinnou atmosférou. Nejsme továrna na nová těla, ale specializovaná klinika s pěti lůžky, kde je dost času na nadstandard.': 'We are exceptional with a personal approach and above-standard care. Our goal is to achieve the most natural look with regard to your personality and your wishes. Your maximum safety, care and comfort is our top priority.',
  'Přijďte navštívit naši kliniku a zeptejte se našich odborníků na cokoliv. Vždy si na vás rádi udělají čas.': 'We have already changed people\'s lives, helped them fulfill their dreams. The highest ratings in independent reviews are proof of that.',
  'Náš tým se skládá ze skvělých profesionálů ve svém oboru. Postará se o Vaše pohodlí, zdraví i bezpečí. Pokud se chcete svěřit do rukou lidem, kteří mají rádi svou práci a záleží jim na tom, co dělají, jste na správném místě.': 'You will be in the best hands of leading and recognized plastic surgeons with years of experience in the Czech Republic and abroad. The priority of our doctors is your satisfaction, health and safety.',
  'Vlastní předoperační vyšetření je velké plus, urychlí tak ranní proces na klinice.': 'Your own preoperative tests are a big plus, the morning process at the clinic is then quicker.',
  'career - info': 'Formé clinic is a plastic and aesthetic clinic with a 8-year history. Our staff gained experience in domestic and foreign clinics. One thing unites us: We put our heart in our work. We make sure that not only our clients but also our staff feel comfortable with us. <br/> <br/> Our clients very often come back to see "their" doctor and highly recommend our services. <Br /> <br/> We are looking for colleagues who will with us emotionally move destinies of people we help, and have a desire to do things differently. Better.',
  'contact form - text': 'It is not easy to decide to undergo a surgery. Do you have any questions or do you need a second opinion? <b>Consult that with our surgeons</b>. Arrange a non-binding <b>consultation</b>. In order that we find a suitable date for the consultation as soon as possible and to discuss any questions you may have, <b>please write us a telephone number and we will be happy to call you</b>.',
  '/// adresy url stranek': '-------',
  '/// meta informace statickych stranek': '------',
  'Klinika pro lidi': 'We change the look, we change the life',
  'faq - meta desc': 'In FAQ you will find the most frequently asked questions that our clients ask us.',
  'Interiér Formé clinic': 'Interior of Formé Clinic',
  'Primář Formé Clinic': 'Head of Formé clinic',
  'Plastický chirurg': 'Plastic surgeon',
  'Plastický chirurg Formé Clinic': 'Plastic surgeon',
  '/team': '/our-team',
  '/references': '/references',
  '/contact': '/contact',
  '/changes': '/makeovers',
  '/foundation': '/foundation',
  '/faq': '/faq',
  'Odeslat': 'Send',
  'Odesílám...': 'Sending...',
  'další proměny': 'More makeovers',
  'Všechny zákroky': 'All procedures',
  'Další volné pozice:': 'More available positions:',
  'Najdete nás 10 minut tramvají od Hlavního nádraží v Praze. Z tramvajové zastávky Hlavní nádraží k nám jedou tramvaje 5, 9, 15 a 26 na zastávku Olšanské náměstí a odtud je to 5 minut směr park Parukářka. Autem můžete parkovat u nás v garážích.': 'You can find us 10 minutes by tram from Prague Main Station. Trams 5, 9, 15 and 26 go from the Hlavní nádraží tram stop to the Olšanské náměstí tram stop and then just 5 minutes walk towards park Parukářka. You can leave your car in our garage.',
  'Plastická a estetická chirurgie v Praze · FORMÉ clinic': 'Plastic and aesthetic surgery in Prague · FORMÉ clinic',
  'GDPR': 'GDPR',
  'Všechna práva vyhrazena.': 'All rights reserved.',
  'Zadejte telefonní číslo v mezinárodním formátu a ozvu se vám.': 'Enter phone number in an international format and I will contact you.',
  'Zobrazit všechny fotografie': 'View all photos',
  'Tady bude text o tom, jak se klienti do Formé Clinic dostanou. Možná info o MHD a hlavně že můžou parkovat v garážích zdarma.': 'You can find us 10 minutes by tram from Praha Hlavní nádraží. Trams 5, 9, 15 and 26 go from the Hlavní nádraží tram stop to the Olšanské náměstí stop and then it is only 5 minutes walk towards Parukářka park. You can also park your car in our garage.',
  'Apartmány': 'Apartments',
  '/apartments': '/apartments',
  'Koronavirus': 'Coronavirus',
  'Kontakt na apartmány': 'Apartments contact',
  'Pro rezervaci apartmánu <br />nás kontaktujte na:': 'Contact us<br />for an apartment reservation:',
  'Plastická chirurgie': 'Plastic surgery',
  'Ortopedie': 'Orthopedics',
  'nové': 'new',
  'nově': 'new',
  'Ortopedické zákroky': 'Orthopedic procedures',
  'Nově otevřeno': 'Newly open',
  'Estetická plastika': 'Aesthetic plastic surgery',
  'Nové': 'New',
  'Díky špičkovému vybavení moderních operačních sálů a profesionálnímu specialozovanému personálu vám Formé Clinic poskytne tu nejlepší péči v oblasti ortopedie. Zabýváme léčbou poruch pohybového aparátu dle nejnovějších medicínských poznatků.': 'Confide yourself in the hands of recognized specialists in orthopedics. We deal with the treatment of musculoskeletal disorders according to the latest medical knowledge. We will provide you the best care with using the top equipment of modern operating theaters.',
  'ortopedické zákroky': 'Orthopedic procedures',
  'Klient koordinátor pro Ortopedii': 'Client Coordinator for Orthopedics',
  'zákroky estetické plastiky': 'procedures of aesthetic plastic surgery',
  'Provádíme kompletní škálu estetických plastických operací - zvětšení a modelaci prsou, liposukci, plastiku nosu a další. Ať už vás k nám přivedou vrozené dispozice, věk, nebo úraz, jsme připraveni vám pomoci.': 'We perform a complete range of aesthetic plastic surgery - breast augmentation and modeling, liposuction, rhinoplasty and more. Whether you are brought to us by innate dispositions, age, or injury, we are ready to help you.',
  'Telefon': 'Phone number',
  'Aktuální informace': 'Current information',
  'Máme pro vás otevřeno!': 'We\'re open',
  'Vaše bezpečnost je naše priorita.': 'Your safety is our top priority.',
  'Dodržujeme pravidlo 3R.': 'We are following the protective measures.',
  'Covid-19 info': 'Covid-19 info',
  'Při provedení všech zákroků a ošetření dbáme na maximální šetrnost a klademe důraz na bezpečnost pro vaše zdraví.': 'We pay maximum attention to the gentleness of all procedures and treatments. Your health is our highest priority. ',
  'Používáme nejmodernější přístroje a postupy s garancí vysoké kvality odborné péče profesionálů uznávaných u nás i v zahraničí.': 'We use the most modern devices and procedures to guarantee high-quality professional care from the best surgeons respected in our country as well as abroad',
  'Cena je orientační a může se lišit na základě vašich konkrétních potřeb a rozsahu zákroku. Upřesněna bude po osobní konzultaci s lékařem.': 'The price is approximate and may vary based on your specific needs and the scope of the procedure. It will be specified after a personal consultation with a doctor.',
  'Používáme nejmodernější přístroje a postupy s garancí vysoké kvality odborné péče profesionálů uznávaných u nás i v zahraničí. ': 'We use the most modern devices and procedures to guarantee high-quality professional care from the best surgeons respected in our country as well as abroad',
  'Velice nás těší Váš zájem o osobní konzultaci ve Formé clinic. Nejpozději do dvou pracovních dnů se Vám ozveme a domluvíme společně termín konzultace, který Vám bude nejvíce vyhovovat. Těšíme se na Vás.': 'We are very pleased with your interest in a personal consultation at the Formé clinic. We will contact you within two working days at the latest and arrange a consultation date that will suit you best. We look forward to seeing you.    ',
  'Váš tým Formé clinic': 'Your Formé clinic team',
  '/dev-404-page': '',
  '/404': '',
  '/404.html': '',
  '/': '/',
  'Formé clinic je zdravotnické zařízení. Návštěva kliniky a veškeré zákroky jsou <b>v souladu s aktuálními vládními opatřeními COVID-19 platnými v České republice.</b>': 'We are a medical facility. The visit to the clinic and all procedures are in accordance with the <b>current government measures COVID-19 valid in the Czech Republic.</b>',
  'Pro rychlejší komunikaci nám prosím napište telefonní číslo.': 'Enter phone number in international format (+44...).',
  '<b>Máme pro vás stále otevřeno.</b> Formé clinic je zdravotnické zařízení. Návštěva kliniky a veškeré zákroky jsou <b>v souladu s aktuálními vládními opatřeními COVID-19 platnými v České republice.</b> V případě cesty z jiného okresu vám vystavíme <b>potvrzení o návštěvě lékaře.</b>': '<b>We\'re still open.</b> Formé clinic is a medical facility. The visit to the clinic and all procedures are <b>in accordance with the current government measures COVID-19 valid in the Czech Republic.</b> We will send you a <b>confirmation letter of your doctor\'s visit</b> for your smooth journey.',
  '           Formé clinic je zdravotnické zařízení. Návštěva kliniky a veškeré zákroky jsou <b>v souladu s aktuálními vládními opatřeními COVID-19 platnými v České republice.</b>': '---empty---',
  'Akceptujeme tyto benefitní karty': '',
  'Nově nabízíme možnost rozložit si cenu zákroku až do 24 měsíčních plateb bez navýšení.': '',
  'Dopřejte si změnu hned a plaťte pak!': '',
  'Plastická a estetická medicína': 'Plastic surgery and aesthetic medicine',
  'Provádíme kompletní škálu estetických plastických operací. Ať už vás k nám přivedou vrozené dispozice, touha po krásnějším vzhledu nebo úraz, jsme připraveni vám pomoci.': 'We perform a complete range of aesthetic plastic surgeries. We are ready to help you, whether you come to us with an innate disposition, a desire for a more beautiful look or injury.',
  'Dopřejte si změnu hned a plaťte pak. Nově nabízíme možnost rozložit si cenu zákroku až do 24 měsíčních plateb bez navýšení.': '',
  'FORMÉ clinic a kontakt': '',
  'plastických operací': '',
  'Odesláním formuláře souhlasíte se': '',
  'zásadami zpracování a ochrany osobních údajů.': '',
  'Telefon je povinný, prosím doplňte': 'Phone number is required, please fill in',
  'Všechny otázky': 'All questions',
  'Trvalá epilace': 'Permanent Epilation',
  'Zbavíme vás nežádoucího ochlupení jednou pro vždy. Jako jediní v České republice disponujeme revolučním lékařským diodovým laserem OPT LED Excelsior 3.0 s unikátní 3D hlavicí, který nám umožňuje odstranit i ty nejjemnější a nejsvětlejší chloupky s téměř 100% účinností.': 'We will get rid of undesirable hair for you once and for all. We are the only facility in the Czech Republic that has the revolutionary OPT LED Excelsior 3.0 medical diode laser with a unique 3D head; it enables us to remove the finest and lightest hairs with almost 100% effectiveness.',
  'Chci se objednat': 'REGISTER HERE',
  'Dermatologie': '',
  'Fotografie před a po': '',
  'Storno podmínky': 'Cancellation terms',
  'Zdravotní pohotovost': 'Medical emergency',}